<template>
  <div>
    <component
      :types="type"
      :activeName="activeName"
      :dataIds="dataId"
      :montype="montype"
      :cityId="cityId"
      :is="name"
    ></component>
  </div>
</template>

<script>
import messageInfo from "../../../components/messageInfo";
import sensitiveXq from "../../../components/sensitiveXq";
export default {
  data() {
    return {
      name: "messageInfo",
      dataId: "",
      montype: "",
      activeName: "",
      cityId: "",
      type: "首页监测"
    };
  },
  components: {
    messageInfo,
    sensitiveXq
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.montype = Number(params.montype);
      this.activeName = params.activeName;
      this.cityId = Number(params.cityId);
    }
    if (this.montype == 1 || this.montype == 0) {
      this.name = "messageInfo";
    } else if (this.montype == 3 || this.montype == 2) {
      this.name = "sensitiveXq";
    }
  }
};
</script>

<style>
</style>